import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import DatePicker from '../date-picker';
import TextField from '../text-field';
import { useTranslation } from '../../hooks/useTranslation';
import { FormState } from '../../hooks/useFormState.types';
import { Model } from '../business-form/BusinessForm.rules';
import MaskedNameTextField from '../masked-name-text-field';

type Props = {
  classes: any;
  form: FormState<Model>;
};

export const BookkeeperFields = (props: Props) => {
  const { t } = useTranslation();
  const { form, classes } = props;
  return (
    <>
      <Grid item xs={12} md={7}>
        <MaskedNameTextField
          name={'firstName'}
          label={t('FIRST_NAME')}
          inputProps={{ minLength: 1, maxLength: 15 }}
          style={{ width: '100%', marginTop: 20 }}
          {...form.props('firstName')}
        />
      </Grid>
      <Grid item xs={12} md={7}>
        <MaskedNameTextField
          name={'middleName'}
          label={t('MIDDLE_NAME')}
          style={{ width: '100%' }}
          inputProps={{ minLength: 1, maxLength: 15 }}
          {...form.props('middleName')}
        />
      </Grid>
      <Grid item xs={12} md={7}>
        <MaskedNameTextField
          name={'lastName'}
          label={t('LAST_NAME')}
          style={{ width: '100%' }}
          inputProps={{ minLength: 1, maxLength: 50 }}
          {...form.props('lastName')}
        />
      </Grid>
      <Grid item xs={12} md={7}>
        <DatePicker
          style={{ width: '100%' }}
          label={t('DATE_OF_BIRTH')}
          name="birthdate"
          {...form.props('birthdate')}
        />
      </Grid>
    </>
  );
};
