import { makeStyles, Theme } from '@material-ui/core/styles';

import colors from '../../themes/main-colors';

const useStyles = makeStyles((theme: Theme) => ({
  submitButton: {
    paddingLeft: 25,
    paddingRight: 25,
    fontSize: 15,
    [theme.breakpoints.down('sm')]: {
      marginTop: 20,
      width: '100%',
    },
  },
  card: {
    textAlign: 'left',
    color: colors.noirBlur,
    padding: 22,
  },
  backLinkDesktop: {
    display: 'inline-block',
    marginRight: 20,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  backLinkMobile: {
    display: 'none',
    margin: 20,
    textAlign: 'center',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  errorText: {
    color: `${theme.palette.error.main} !important`,
  },
}));

export default useStyles;
