import React from 'react';
import MaskedInput from 'react-text-mask';

import { useTranslation } from '../../hooks/useTranslation';
import TextField, { TextFieldProps } from '../text-field/TextField';

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

// Invalid Characters ^ < > _ = { } [ ] ; |
const TextMaskCustom = (textMaskProps: TextMaskCustomProps | any) => {
  const { inputRef, ...other } = textMaskProps;

  return (
    <MaskedInput
      {...other}
      name={textMaskProps.name || 'name'}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      data-testid={'nameField'}
      mask={s => Array.from(s).map(() => /[^\^<>_={}\[\];|]/)}
      maxLength={textMaskProps.maxLength || 250}
      guide={false}
      type={'text'}
      showMask
    />
  );
};

export default React.forwardRef(
  (props: TextFieldProps | any, ref?: React.Ref<HTMLInputElement>) => {
    const { t } = useTranslation();
    const label = props.label || t('NAME');

    return (
      <TextField
        label={label}
        onBlur={props.onBlur}
        disabled={!!props.disabled}
        style={props.style || { width: '100%' }}
        ref={ref}
        maxLength={props.maxLength}
        inputProps={{
          ...props.inputProps,
          'data-testid': 'nameField',
        }}
        InputProps={{
          inputComponent: TextMaskCustom,
        }}
        type={'text'}
        name={props.name || 'name'}
        margin={'normal'}
        error={!!props.error}
        helperText={props.helperText || props.error}
        onChange={props.onChange}
        inputRef={props.inputRef}
        defaultValue={props.defaultValue || null}
      />
    );
  },
);
