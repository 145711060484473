import { useTranslation } from '../../hooks/useTranslation';
import { isValidPassword, validateEmail } from '../../util/form-validation';
import { parsePhoneNumber } from '../../util/format';
import { convertValidationRules } from '../../hooks/useFormState';
import { BaseValidationContext } from '../../hooks/useFormState.types';
import { parse, isPast, isBefore } from 'date-fns';
import {
  CommercialRegistrationRequest,
  VerificationType,
} from '../../__generated__/pge-types';
const { t } = useTranslation();

export type Model = {
  email: string;
  firstName: string;
  middleName: string;
  lastName: string;
  birthdate: string;
  password: string;
  pinCode: string;
  businessName: string;
  verificationType: 'phone' | 'ein';
  phone: string;
  ein: string;
};

export type AdditionalContext = {
  isBookkeeperForm: boolean;
};

const validatePassword = (password = ''): string | null => {
  if (!password.length) {
    return t('ERROR_AUTH_PASSWORD_REQUIRED');
  }
  if (!isValidPassword(password) || password.length < 8) {
    return t('ERROR_AUTH_INVALID_PASSWORD_RULES');
  }
  return null;
};

const validatePhoneNumber = (phoneNumber = ''): string | null => {
  const parsedPhoneNumber = parsePhoneNumber(phoneNumber);
  if (parsedPhoneNumber.length < 1) {
    return t('ERROR_PHONE_REQUIRED');
  }
  if (parsedPhoneNumber.length < 10) {
    return t('ERROR_PHONE_LENGTH');
  }
  return null;
};

const validatePinCode = (pin = ''): string | null => {
  if (pin.length > 0 && pin.length < 4) {
    return t('ERROR_PIN_CODE_REQUIRED');
  }
  return null;
};

const validateNameField = (error: string, value: any) => {
  return !value.length ? error : null;
};

const birthdayPattern = /\d{2}\/\d{2}\/\d{4}/;
const minDateString = '01/01/1901';
const minDate = parse(minDateString, 'MM/dd/yyyy', new Date(0));

const validateBirthdate = (dob: string) => {
  if (!dob || dob === '') {
    return t('DATE_OF_BIRTH_REQUIRED');
  }
  if (!birthdayPattern.test(dob)) {
    return t('DATE_OF_BIRTH_INVALID');
  }

  const date = parse(dob, 'MM/dd/yyyy', new Date(0));
  if (!isPast(date)) {
    return t('DATE_OF_BIRTH_IN_PAST');
  }
  if (isBefore(date, minDate)) {
    return t('DATE_OF_BIRTH_TOO_OLD');
  }
  return null;
};

function validateEIN(ein: string) {
  if (ein.length < 10) {
    return t('ENTER_EIN_NUMBER');
  }
  return null;
}

export default convertValidationRules<Model, AdditionalContext>(
  (context: BaseValidationContext<Model> & AdditionalContext) => {
    return {
      // this must match the names of the form fields
      email: validateEmail,
      password: validatePassword,
      pinCode: validatePinCode,
      businessName: !context.isBookkeeperForm
        ? validateNameField.bind(null, t('ENTER_BUSINESS_NAME'))
        : null,
      phone:
        !context.isBookkeeperForm && context.values.verificationType === 'phone'
          ? validatePhoneNumber
          : null,
      ein:
        !context.isBookkeeperForm && context.values.verificationType === 'ein'
          ? validateEIN
          : null,
      firstName: context.isBookkeeperForm
        ? validateNameField.bind(null, t('ENTER_FIRST_NAME'))
        : null,
      lastName: context.isBookkeeperForm
        ? validateNameField.bind(null, t('ENTER_LAST_NAME'))
        : null,
      birthdate: context.isBookkeeperForm ? validateBirthdate : () => null,
    };
  },
);

export function createRegistrationPayload(
  accountNumber: string,
  isBookkeeper: boolean,
  data: Model,
): CommercialRegistrationRequest {
  const {
    email,
    firstName,
    middleName,
    lastName,
    businessName,
    password,
    birthdate,
    verificationType,
    phone,
    ein,
  } = data;

  const businessVerificationValue =
    verificationType === 'phone' ? phone.replace(/\D/g, '') : ein;
  const businessVerificationType = isBookkeeper
    ? 'DOB'
    : verificationType === 'phone'
    ? 'PHONE'
    : 'EIN';

  return {
    AccountNumber: accountNumber,
    EmailAddress: email,
    FirstName: firstName || businessName,
    MiddleName: middleName,
    LastName: lastName || businessName,
    Password: password,
    // Enum: “Employer Identification Number” -or- “Primary Notification Phone” based on user selection
    // DOB for the book keeper form.
    VerificationType: businessVerificationType as VerificationType,
    VerificationValue: isBookkeeper
      ? birthdate.replace(/\//g, '')
      : businessVerificationValue,
    IsCoApplicantOfCommercialAccount: isBookkeeper,
  };
}

export function createGroupName(
  isBookkeeper: boolean,
  { firstName, middleName, lastName, businessName }: Model,
) {
  if (isBookkeeper) {
    const nameArray = middleName
      ? [firstName, middleName, lastName]
      : [firstName, lastName];
    return nameArray.join(' ');
  }
  return businessName;
}
