import React from 'react';
import Grid from '@material-ui/core/Grid';
import { BusinessVerificationFields } from './BusinessVerificationFields';
import { BookkeeperFields } from './BookkeeperFields';
import ROUTES from '../../routes';
import { FormState } from '../../hooks/useFormState.types';
import { Model } from '../business-form/BusinessForm.rules';

type Props = {
  classes: any;
  form: FormState<Model>;
  path?: string;
};

const BusinessCustomerSection = (props: Props) => {
  const { path } = props;

  return (
    <Grid
      container
      spacing={1}
      style={{ padding: '1em', justifyContent: 'flex-start' }}
    >
      {path === ROUTES.REGISTRATION_BUSINESS_FORM && (
        <BusinessVerificationFields {...props} />
      )}

      {path === ROUTES.REGISTRATION_BUSINESS_FORM_ACCOUNTANT && (
        <BookkeeperFields {...props} />
      )}
    </Grid>
  );
};

export default BusinessCustomerSection;
