import React from 'react';
import MaskedInput from 'react-text-mask';

import { useTranslation } from '../../hooks/useTranslation';
import TextField, { TextFieldProps } from '../text-field/TextField';

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

const numeric = /[0-9]/;

const mask = [
  numeric,
  numeric,
  '-',
  numeric,
  numeric,
  numeric,
  numeric,
  numeric,
  numeric,
  numeric,
];

const TextMaskCustom = (textMaskProps: TextMaskCustomProps | any) => {
  const { inputRef, inputProps, ...other } = textMaskProps;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={mask}
      guide={false}
      showMask
    />
  );
};

const EinTextField = React.forwardRef(
  (props: TextFieldProps | any, ref?: React.Ref<HTMLInputElement>) => {
    const { t } = useTranslation();
    const label = props.label || t('EIN_NUMBER');

    return (
      <TextField
        label={label}
        ref={ref}
        style={props.style || { width: '100%' }}
        InputProps={{
          ...props.InputProps,
          inputComponent: TextMaskCustom,
        }}
        inputProps={{
          ...props.inputProps,
          maxLength: 10,
          minLength: 10,
          type: 'tel', // Use tel instead of number to avoid browser behaviour
        }}
        {...props}
      />
    );
  },
);
export default EinTextField;
