import React from 'react';
import Modal from '../modal';
import { useTranslation } from '../../hooks/useTranslation';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import PGEButton from '../buttons';

type Props = {
  open: boolean;
  onRedirect: () => void;
  onContinue: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    body: {
      maxWidth: 600,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      '& > *': {
        marginTop: theme.spacing(1),
      },
    },
    cancelButton: {
      border: 'none !important',
      marginRight: theme.spacing(1),
    },
    bold: {
      fontWeight: 'bold',
    },
  }),
);

export default function ResidentialRedirectModal({
  open,
  onRedirect,
  onContinue,
}: Props) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Modal
      open={open}
      showCloseIcon={false}
      title={
        <Typography variant="h2">{t('COMMERCIAL_REDIRECT_TITLE')}</Typography>
      }
    >
      <Box className={classes.body}>
        <Typography>{t('COMMERCIAL_REDIRECT_COPY_1')}</Typography>

        <Typography className={classes.bold}>
          {t('COMMERCIAL_REDIRECT_COPY_2')}
        </Typography>

        <Typography>{t('COMMERCIAL_REDIRECT_COPY_3')}</Typography>
      </Box>
      <Box justifyContent="flex-end" display="flex">
        <PGEButton
          className={classes.cancelButton}
          color={'secondary'}
          variant={'outlined'}
          onClick={onRedirect}
        >
          {t('NO')}
        </PGEButton>
        <PGEButton color={'primary'} variant={'contained'} onClick={onContinue}>
          {t('YES')}
        </PGEButton>
      </Box>
    </Modal>
  );
}
