import React, { FunctionComponent } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Link } from '@reach/router';
import Button from '../buttons';
import { useTranslation } from '../../hooks/useTranslation';
import clsx from 'clsx';

type CancelVariant = 'Link' | 'Button';

interface ProceedOrCancelProps {
  cancelRoute: string | false;
  cancelHandler?: () => void | any;
  proceedHandler: () => void | any;
  proceedLabel?: string;
  proceedDisabled?: boolean;
  cancelLabel?: string;
  cancelVariant?: CancelVariant;
  proceedStyle?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    paddingLeft: 25,
    paddingRight: 25,
    fontSize: 15,
    [theme.breakpoints.down('sm')]: {
      marginTop: 20,
      width: '100%',
    },
  },
  backButton: {
    background: 'white',
  },
  backLinkDesktop: {
    display: 'inline-block',
    marginRight: 20,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  backLinkMobile: {
    display: 'none',
    textAlign: 'center',
    width: '100%',
    marginTop: 20,
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}));

function ButtonLink(props: any) {
  const classes = useStyles();
  return (
    <Button
      color="secondary"
      variant="outlined"
      component={Link}
      {...props}
      className={clsx(classes.button, classes.backButton, props.className)}
    />
  );
}

const ProceedOrCancel: FunctionComponent<ProceedOrCancelProps> = ({
  cancelRoute,
  cancelHandler,
  proceedHandler,
  proceedDisabled,
  cancelLabel = '',
  proceedLabel = '',
  cancelVariant = 'Link',
  proceedStyle = '',
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const CancelComponent = cancelVariant === 'Link' ? Link : ButtonLink;

  return (
    <Grid
      container
      justify="flex-end"
      alignContent="center"
      spacing={2}
      alignItems="center"
    >
      {cancelRoute && (
        <CancelComponent
          className={classes.backLinkDesktop}
          to={cancelRoute}
          onClick={cancelHandler}
        >
          {cancelLabel === '' ? t('CANCEL') : cancelLabel}
        </CancelComponent>
      )}
      <Button
        variant={'contained'}
        color={'primary'}
        className={classes.button}
        onClick={proceedHandler}
        disabled={proceedDisabled}
        style={
          proceedStyle && !proceedDisabled
            ? { backgroundColor: proceedStyle }
            : {}
        }
        data-testid={'proceed-or-cancel_proceed'}
      >
        {proceedLabel === '' ? t('SUBMIT') : proceedLabel}
      </Button>
      {/* This is for mobile display diff, could float as alternative */}
      {cancelRoute && (
        <CancelComponent
          className={classes.backLinkMobile}
          to={cancelRoute}
          onClick={cancelHandler}
          data-testid={'proceed-or-cancel_cancel'}
        >
          {cancelLabel === '' ? t('CANCEL') : cancelLabel}
        </CancelComponent>
      )}
    </Grid>
  );
};

export default ProceedOrCancel;
