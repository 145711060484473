import { validateAccountNumber } from '../../util/form-validation';
import { convertValidationRules } from '../../hooks/useFormState';
import { ApolloClient } from 'apollo-client';

export type Model = {
  accountNumber: string;
};

type AdditionalContext = {
  t: (translationField: string) => string;
  apolloClient: ApolloClient<any>;
};

export default convertValidationRules<Model, AdditionalContext>(() => ({
  // this must match the names of the form fields
  accountNumber: async (value, { t }) => {
    if (value.length === 0) {
      return t('ENTER_ACCOUNT_NUMBER');
    }
    const error = validateAccountNumber(value);
    if (error) {
      return error;
    }

    return null;
  },
}));
