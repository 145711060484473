import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Theme, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Link } from 'gatsby';
import React, { FunctionComponent } from 'react';

import { useTranslation } from '../../hooks/useTranslation';
import ROUTES from '../../routes';
import colors from '../../themes/main-colors';
import AccountTextField from '../account-text-field';
import SampleBill from '../sample-bill';
import AccountLookupProps from './AccountLookup.types';
import useFormState from '../../hooks/useFormState';
import { useApolloClient } from '@apollo/react-hooks';
import validate, { Model } from './AccountLookup.rules';
import useWrapWithLoader from '../../hooks/useWrapWithLoading';

const useStyles = makeStyles((theme: Theme) => ({
  submitButton: {
    paddingLeft: 25,
    paddingRight: 25,
    fontSize: 15,
    [theme.breakpoints.down('sm')]: {
      marginTop: 20,
      width: '100%',
    },
  },
  card: {
    textAlign: 'left',
    color: colors.noirBlur,
    padding: 22,
  },
  backLinkDesktop: {
    display: 'inline-block',
    marginRight: 20,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  backLinkMobile: {
    display: 'none',
    margin: 20,
    textAlign: 'center',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  cardTitle: {
    marginBottom: 15,
  },
}));

export const defaultFormValues: Model = {
  accountNumber: '',
};

const AccountLookup: FunctionComponent<AccountLookupProps> = props => {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const { wrapWithLoader } = useWrapWithLoader();

  const defaultValues =
    props.defaultAccountNumber !== undefined
      ? { accountNumber: props.defaultAccountNumber }
      : defaultFormValues;

  const form = useFormState(defaultValues, {
    validate,
    validationContext: { t, apolloClient: useApolloClient() },
  });

  const { onSubmit } = props;

  const handleSubmit = wrapWithLoader(
    form.submit(data =>
      onSubmit(data, error => form.setError('accountNumber', error)),
    ),
  );

  return (
    <div>
      <Paper component={'main'} style={{ justifyContent: 'flex-start' }}>
        <Card className={classes.card}>
          <Typography variant={'h2'} className={classes.cardTitle}>
            {t('ACCOUNT_LOOKUP_SUBTITLE')}
          </Typography>

          <Typography variant={'body1'}>{t('ACCOUNT_LOOKUP_TEXT')}</Typography>

          <form noValidate onSubmit={handleSubmit}>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} md={5}>
                <AccountTextField
                  autoFocus
                  data-testid={'create-business-acccount-number'}
                  name={'accountNumber'}
                  label={t('ACCOUNT_NUMBER')}
                  autoComplete={t('ACCOUNT_NUMBER')}
                  inputProps={{ maxLength: 10 }}
                  {...form.props('accountNumber')}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <SampleBill />
              </Grid>
            </Grid>

            <Grid
              container
              justify="flex-end"
              alignContent="center"
              spacing={2}
              alignItems="center"
            >
              <Link
                className={classes.backLinkDesktop}
                to={ROUTES.REGISTRATION}
              >
                {t('CANCEL')}
              </Link>
              <Button
                variant={'contained'}
                color={'primary'}
                className={classes.submitButton}
                onClick={handleSubmit}
              >
                {t('NEXT')}
              </Button>
              {/* This is for mobile display diff, could float as alternative */}
              <Link className={classes.backLinkMobile} to={ROUTES.REGISTRATION}>
                {t('CANCEL')}
              </Link>
            </Grid>
          </form>
        </Card>
      </Paper>
    </div>
  );
};

export default AccountLookup;
