import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { navigate } from 'gatsby';
import React, { FunctionComponent, useEffect } from 'react';

import { useTranslation } from '../../hooks/useTranslation';
import ROUTES from '../../routes';
import colors from '../../themes/main-colors';
import BusinessCustomerSection from '../business-customer-section';
import ProceedOrCancel from '../proceed-or-cancel';
import UserAccountSection from '../user-account-section';
import BusinessFormProps from './BusinessForm.types';
import validationRules, { Model } from './BusinessForm.rules';
import useFormState from '../../hooks/useFormState';
import useWrapWithLoader from '../../hooks/useWrapWithLoading';
import useStyles from './BusinessForm.styles';

export const defaultFormValues: Model = {
  email: '',
  firstName: '',
  middleName: '',
  lastName: '',
  birthdate: '',
  password: '',
  pinCode: '',
  businessName: '',
  verificationType: 'phone',
  phone: '',
  ein: '',
};

const BusinessForm: FunctionComponent<BusinessFormProps> = props => {
  // without an account number we have lost our state / arrived direct. force back.
  useEffect(() => {
    if (props.accountNumber === '') {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      navigate(ROUTES.REGISTRATION_CREATE_BUSINESS);
    }
  }, [props.accountNumber]);
  const { wrapWithLoader } = useWrapWithLoader();

  const classes = useStyles(props);
  const { t } = useTranslation();
  const form = useFormState(defaultFormValues, {
    validate: validationRules,
    validationContext: {
      isBookkeeperForm: props.isBookkeeper,
    },
  });

  const handleSubmit = wrapWithLoader(
    form.submit(data => props.onSubmit(data, form)),
  );

  return (
    <>
      <Paper component={'main'} style={{ justifyContent: 'flex-start' }}>
        <Card className={classes.card}>
          <CardContent>
            <Typography variant={'h2'}>
              {t('BUSINESS_REGISTRATION_CUSTOMER_SUBTITLE')}
            </Typography>
          </CardContent>
          {props.customerInfoError && (
            <CardContent>
              <Typography variant="body1" className={classes.errorText}>
                {props.customerInfoError}
              </Typography>
            </CardContent>
          )}
          <form noValidate onSubmit={handleSubmit}>
            <BusinessCustomerSection
              path={props.path}
              classes={classes}
              form={form}
            />

            <div
              style={{
                height: 1,
                background: colors.lightGray1,
              }}
            />

            <UserAccountSection
              isBusinessRegistration
              form={form}
              classes={classes}
              isMobile={props.isMobile}
            />
          </form>
          <ProceedOrCancel
            proceedHandler={handleSubmit}
            cancelRoute={ROUTES.REGISTRATION_CREATE_BUSINESS}
            proceedLabel={t('REGISTER')}
            cancelLabel={t('PREVIOUS')}
            proceedStyle={colors.orange}
          />
        </Card>
      </Paper>
    </>
  );
};

export default BusinessForm;
