import { Router, useLocation, navigate } from '@reach/router';
import { Link } from 'gatsby';
import React, { useState, useRef, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import AccountLookup from '../../components/account-lookup';
import AuthLayout from '../../components/auth-layout';
import BusinessForm, {
  ResidentialRedirectModal,
} from '../../components/business-form/';
import { useTranslation } from '../../hooks/useTranslation';
import ROUTES from '../../routes';
import { RegistrationPageProps } from './index';
import gql from 'not-graphql-tag';
import { Model as AccountLookupModel } from '../../components/account-lookup/AccountLookup.rules';
import {
  CommercialAccountValidation,
  CommercialRegistrationRequest,
  VerificationType,
  RegistrationResponse,
} from '../../__generated__/pge-types';
import { useApolloClient } from '@apollo/react-hooks';
import {
  Model as BusinessModel,
  createRegistrationPayload,
  createGroupName,
} from '../../components/business-form/BusinessForm.rules';
import { FormState } from '../../hooks/useFormState.types';
import { NotificationsContext } from '../../providers/NotificationsProvider';
import useAuth from '../../hooks/useAuth';
import { useIsMobile } from '../../util/style-utils';

const validateCommercialAccount = gql`
  query validateCommercialAccountNumber($accountNumber: String!) {
    validateCommercialAccountNumber(accountNumber: $accountNumber) {
      isValidAccountNumber
      isValidBusinessAccount
      shouldUseBookkeeperRegistration
    }
  }
`;

const registrationMutation = gql`
  mutation registerCommercial($payload: CommercialRegistrationRequest!) {
    registerCommercial(payload: $payload) {
      success
      signinToken
      uid
    }
  }
`;

export const RegistrationErrors = {
  INVALID_BUSINESS_INFORMATION: 'INVALID_BUSINESS_INFORMATION',
  USERNAME_IN_USE: 'USERNAME_IN_USE',
};

const CreateBusinessPage = (props: RegistrationPageProps) => {
  const apolloClient = useApolloClient();
  const { t, richT } = useTranslation();
  const notificationContext = useContext(NotificationsContext);
  const [redirectUrl, setRedirectUrl] = useState<string | undefined>();

  const location = useLocation();
  // sets isBookkeeperForm at top state level for validation
  // (driven by routing)
  const isBookkeeper =
    location.pathname === ROUTES.REGISTRATION_BUSINESS_FORM_ACCOUNTANT;

  const locationState = location.state as any;
  useEffect(() => {
    setRedirectUrl(locationState?.redirectUrl as string | undefined);
  }, []);

  const title = t('BUSINESS_REGISTRATION_HEADER');
  const isMobile = useIsMobile();
  const [accountNumber, setAccountNumber] = useState('');
  const [redirectModalOpen, setRedirectModalOpen] = useState(false);
  const [infoError, setInfoError] = useState<string | undefined>();
  const errorCount = useRef(0);
  const { signInWithCustomToken } = useAuth();

  async function handleAccountLookupSubmit(
    formData: AccountLookupModel,
    onFormError: (error: string) => void,
  ): Promise<void> {
    // The AccountLookup form rules ensures by the time we get to submit
    // The account number is a valid account number.
    const { data } = await apolloClient.query<{
      validateCommercialAccountNumber: CommercialAccountValidation;
    }>({
      query: validateCommercialAccount,
      variables: {
        accountNumber: formData.accountNumber,
      },
      fetchPolicy: 'network-only',
    });
    const isValid =
      data?.validateCommercialAccountNumber?.isValidAccountNumber || false;

    if (!isValid) {
      return onFormError(t('ERROR_ACCOUNT_NUMBER_REQUIRED'));
    }

    const isBusiness =
      data?.validateCommercialAccountNumber?.isValidBusinessAccount || false;
    const shouldUseBookkeeper =
      data?.validateCommercialAccountNumber?.shouldUseBookkeeperRegistration ||
      false;

    setAccountNumber(formData.accountNumber);

    if (!isBusiness) {
      return setRedirectModalOpen(true);
    }

    if (shouldUseBookkeeper) {
      return navigate(ROUTES.REGISTRATION_BUSINESS_FORM_ACCOUNTANT);
    }
    return navigate(ROUTES.REGISTRATION_BUSINESS_FORM);
  }

  function handleRegistrationError(
    payload: CommercialRegistrationRequest,
    form: FormState<BusinessModel>,
    error: string,
  ) {
    errorCount.current += 1;
    if (errorCount.current > 2) {
      notificationContext.setState({
        isOpen: true,
        message: richT('REGISTRATION_HELP_TEXT', {
          PHONE_NUMBER: t('BUSINESS_CUSTOMER_SERVICE_NUMBER'),
        }),
        severity: 'error',
      });
    }

    if (error === RegistrationErrors.USERNAME_IN_USE) {
      return form.setError(
        'email',
        <span>
          {t('ALREADY_REGISTERED_SERVICES_1')}{' '}
          <Link to={ROUTES.SIGN_IN}>{t('SIGN_IN')}</Link>{' '}
          {t('ALREADY_REGISTERED_SERVICES_2')}
        </span>,
      );
    } else if (error === RegistrationErrors.INVALID_BUSINESS_INFORMATION) {
      if (isBookkeeper) {
        setInfoError(t('BOOKKEEPER_INVALID_BUSINESS_INFORMATION'));
      } else {
        if (payload.VerificationType === VerificationType.Phone) {
          setInfoError(t('COMMERCIAL_PHONE_INVALID_BUSINESS_INFORMATION'));
        } else {
          setInfoError(t('COMMERCIAL_EIN_INVALID_BUSINESS_INFORMATION'));
        }
      }
      return window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } else {
      throw new Error(error);
    }
  }

  async function handleRegistration(
    formData: BusinessModel,
    form: FormState<BusinessModel>,
  ) {
    setInfoError(undefined);
    const payload = createRegistrationPayload(
      accountNumber,
      isBookkeeper,
      formData,
    );

    const { data, errors } = await apolloClient.mutate<{
      registerCommercial: RegistrationResponse;
    }>({
      mutation: registrationMutation,
      variables: {
        payload,
      },
      errorPolicy: 'all',
    });

    if (errors && errors.length > 0) {
      const message = errors[0].extensions?.debug?.message;
      return handleRegistrationError(payload, form, message);
    } else {
      const info = data?.registerCommercial!;
      await signInWithCustomToken(info.signinToken!);

      const groupName = createGroupName(isBookkeeper, formData);

      if (redirectUrl) {
        return navigate(redirectUrl);
      }

      return navigate(ROUTES.REGISTRATION_COMPLETE, {
        state: {
          uid: info.uid,
          signInToken: info.signinToken,
          groupName,
          email: formData.email,
        },
        replace: true,
      });
    }
  }

  return (
    <AuthLayout
      title={title}
      includePrivacyAgreement={Boolean(accountNumber)}
      showLeft={false}
    >
      <Helmet>
        <title>{t('REGISTRATION')}</title>
      </Helmet>
      <Router basepath="/">
        <AccountLookup
          path={ROUTES.REGISTRATION_CREATE_BUSINESS}
          onSubmit={handleAccountLookupSubmit}
          defaultAccountNumber={accountNumber}
        />
        <BusinessForm
          path={ROUTES.REGISTRATION_BUSINESS_FORM}
          accountNumber={accountNumber}
          isMobile={isMobile}
          isBookkeeper={isBookkeeper}
          onSubmit={handleRegistration}
          customerInfoError={infoError}
        />
        <BusinessForm
          path={ROUTES.REGISTRATION_BUSINESS_FORM_ACCOUNTANT}
          accountNumber={accountNumber}
          isMobile={isMobile}
          isBookkeeper={isBookkeeper}
          onSubmit={handleRegistration}
          customerInfoError={infoError}
        />
      </Router>
      <ResidentialRedirectModal
        open={redirectModalOpen}
        onContinue={() => {
          setRedirectModalOpen(false);
          return navigate(ROUTES.REGISTRATION_BUSINESS_FORM_ACCOUNTANT);
        }}
        onRedirect={() => {
          setRedirectModalOpen(false);
          return navigate(ROUTES.REGISTRATION_CREATE_RESIDENTIAL, {
            state: {
              accountNumber,
            },
          });
        }}
      />
    </AuthLayout>
  );
};

export default CreateBusinessPage;
