import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import React, { FunctionComponent } from 'react';

import { useTranslation } from '../../hooks/useTranslation';
import BusinessFormProps from '../business-form/BusinessForm.types';
import EmailTextField from '../email-text-field';
import PasswordIndicatorList from '../password-indicator-list';
import PasswordTextField from '../password-text-field';
import PinTextField from '../pin-text-field';
import ResidentialFormProps from '../residential-form/ResidentialForm.types';

const UserAccountSection: FunctionComponent<
  BusinessFormProps | ResidentialFormProps | any
> = props => {
  const { t } = useTranslation();
  const { form, isStartServiceForm, isBusinessRegistration } = props;

  return (
    <Grid
      container
      spacing={1}
      style={{
        padding: !isStartServiceForm ? '1em' : 0,
        justifyContent: 'flex-start',
      }}
    >
      {!isStartServiceForm && (
        <Grid item xs={12}>
          <Typography
            variant={'h2'}
            style={{ marginBottom: 20, marginTop: 20 }}
          >
            {t('CREATE_SIGN_IN')}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12} md={7}>
        <EmailTextField
          label={t('EMAIL_ADDRESS')}
          style={{ width: '100%' }}
          {...form.props('email')}
        />
      </Grid>
      {isStartServiceForm && (
        <Grid item xs={12} md={7}>
          <FormControlLabel
            style={{ height: 50, marginTop: 10 }}
            control={
              <Checkbox
                name={'registerForOnline'}
                color={'primary'}
                {...form.checkProps('registerForOnline')}
              />
            }
            label={t('ONLINE_ACCESS')}
          />
          <Typography variant="body2">
            {t('ONLINE_ACCESS_DESCRIPTION')}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12} md={7}>
        <PasswordTextField
          style={{ width: '100%' }}
          defaultShow={props.isMobile}
          inputProps={{ minLength: 8, maxLength: 16 }}
          {...form.props('password')}
        />
      </Grid>
      <Grid item md={5} xs={12}>
        <PasswordIndicatorList password={form.values.password} />
      </Grid>
      {!isStartServiceForm && !isBusinessRegistration && (
        <Grid item xs={12}>
          <Grid item xs={12}>
            <CardContent>
              <Typography
                component={'span'}
                color={'textPrimary'}
                variant={'h4'}
              >
                {t('CREATE_RESIDENTIAL_PIN_CODE')}
              </Typography>
            </CardContent>
          </Grid>
          <Grid item xs={12} md={7}>
            <PinTextField
              label={t('FORGOT_PASSWORD_PIN_CODE')}
              name={'pinCode'}
              type={'password'}
              {...form.props('pinCode')}
              style={{ width: '100%' }}
              inputProps={{ minLength: 4, maxLength: 4 }}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default UserAccountSection;
