import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import XIcon from '@material-ui/icons/Close';
import React from 'react';

import { useTranslation } from '../../hooks/useTranslation';
import {
  hasValidDigit,
  hasValidLength,
  hasValidLowerCase,
  hasValidUpperCase,
} from '../../util/form-validation';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      maxWidth: 752,
      '& .MuiListItemIcon': {
        minWidth: '20px',
      },
    },
    icon: {
      fontSize: '16px',
      color: theme.palette.success.main,
    },
    icon_invalid: {
      fontSize: '16px',
      color: theme.palette.error.main,
    },
  }),
);

interface PasswordIndicatorProps {
  password: string;
  classes?: any;
}

export default (props: PasswordIndicatorProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { password = '' } = props;
  const passwordRequirements = {
    hasLength: {
      isValid: hasValidLength(password),
      text: t('REGEX_LENGTH'),
    },
    hasUpperCase: {
      isValid: hasValidUpperCase(password),
      text: t('REGEX_UPPER'),
    },
    hasLowerCase: {
      isValid: hasValidLowerCase(password),
      text: t('REGEX_LOWER'),
    },
    hasDigit: {
      isValid: hasValidDigit(password),
      text: t('REGEX_DIGIT'),
    },
  };
  const getIcon = (isValid: boolean) => (isValid ? CheckIcon : XIcon);

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <List>
            {Object.values(passwordRequirements).map(
              ({ isValid, text }, index) => {
                const Icon = getIcon(isValid);

                return (
                  <ListItem
                    key={`text-${index.toString()}`}
                    style={{ height: 23 }}
                  >
                    <ListItemIcon style={{ minWidth: '30px' }}>
                      <Icon
                        className={
                          isValid ? classes.icon : classes.icon_invalid
                        }
                        fontSize={'small'}
                      />
                    </ListItemIcon>

                    <Typography
                      variant={'caption'}
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      {text}
                    </Typography>
                  </ListItem>
                );
              },
            )}
          </List>
        </Grid>
      </Grid>
    </div>
  );
};
