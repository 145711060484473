import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PhoneTextField from '../phone-text-field';
import { useTranslation } from '../../hooks/useTranslation';
import EitherField from '../either-field';
import { Model } from '../business-form/BusinessForm.rules';
import { FormState } from '../../hooks/useFormState.types';
import EinTextField from '../ein-text-field';
import MaskedNameTextField from '../masked-name-text-field';

type Props = {
  classes: any;
  form: FormState<Model>;
};

export const BusinessVerificationFields = (props: Props) => {
  const { t } = useTranslation();
  const { classes, form } = props;
  return (
    <>
      <Grid item xs={12} md={7}>
        <MaskedNameTextField
          name={'businessName'}
          label={t('BUSINESS_NAME')}
          style={{ width: '100%', marginTop: 20 }}
          inputProps={{ minLength: 1, maxLength: 254 }}
          {...form.props('businessName')}
        />
      </Grid>
      <Typography
        className={classes.colorTextPrimary}
        component={'div'}
        color={'textPrimary'}
        variant={'body1'}
        style={{ width: '100%', padding: 5, marginTop: 15, marginBottom: 8 }}
      >
        {t('IDENTIFY_YOUR_BUSINESS_WITH_PHONE_OR_EIN')}
      </Typography>
      <EitherField
        aria-label={'create-residential-phone-number-account-number'}
        form={form}
        selectorField="verificationType"
        inputFields={['phone', 'ein']}
        renderLeft={
          <PhoneTextField
            data-testid="validate-phone-number"
            name="phone"
            label={t('PHONE_NUMBER')}
            autoComplete="telephone"
            className={classes.input}
            inputProps={{ type: 'telephone' }}
          />
        }
        renderRight={
          <EinTextField data-testid="validate-ein-number" name="ein" />
        }
      />
    </>
  );
};
